<script setup>
import {ref, reactive, onBeforeMount} from "vue"
import request from "../request"
import {useStore} from "vuex"

const store = useStore()

const form = ref(null)
const formData = reactive({
	first_name: null,
	last_name: null
})
const email = ref(null)

const loading = ref(false)
const saving = ref(false)

const getUserInfo = () => {
	loading.value = true

	request.get(process.env.VUE_APP_BASE_URL + '/me')
		.then(data => {
			loading.value = false
			formData.first_name = data.first_name
			formData.last_name = data.last_name
			email.value = data.email
		})
		.catch(error => {
			loading.value = false
		})
}

onBeforeMount(() => {
	getUserInfo()
})

const save = () => {
	saving.value = true

	request.put(process.env.VUE_APP_BASE_URL + '/users/' + store.getters.userId, {
		first_name: formData.first_name,
		last_name: formData.last_name,
	})
		.then(response => {
			saving.value = false
			const data = response.data

			formData.first_name = data.first_name
			formData.last_name = data.last_name
			email.value = data.email
		})
		.catch(error => {
			saving.value = false
		})
}

</script>

<template>
	<var-skeleton :rows="7" :loading="loading">
		<var-card
			title="Profile"
			class="mb-4"
		>
			<template #extra>
				<var-form ref="form" :disabled="saving">
					<var-row :gutter="10">
						<var-col :span="12">
							<var-input
								placeholder="First Name"
								:rules="[v => !!v || 'The first name cannot be empty']"
								v-model="formData.first_name"
								class="mb-4"
							/>
						</var-col>

						<var-col :span="12">
							<var-input
								placeholder="Last Name"
								:rules="[v => !!v || 'The last name cannot be empty']"
								v-model="formData.last_name"
								class="mb-4"
							/>
						</var-col>
					</var-row>
					<var-input
						placeholder="Email"
						v-model="email"
						disabled
						class="mb-4"
					/>
					<var-row justify="flex-end">
						<var-col :span="8" class="text-end">
							<var-button
								type="primary"
								:loading="saving"
								@click="save"
							>
								Save
							</var-button>
						</var-col>
					</var-row>
				</var-form>
			</template>
		</var-card>
	</var-skeleton>
</template>

<style scoped>

</style>